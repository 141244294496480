import AboutPage from './pages/about';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import { RootAuthPermissions } from 'types';

interface AboutAppProps {
  permissions: RootAuthPermissions;
  themeService: unknown;
}

const RootApp = (props: AboutAppProps) => {
  return (
    <App
      componentToRender={
        <AboutPage tilePermissions={props.permissions.homeAuthorization} />
      }
      route="/about"
      themeService={null}
      permissions={props.permissions.homeAuthorization}
    />
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootApp,
  errorBoundary() {
    return <div>This renders when a catastrophic error occurs</div>;
  },
});

let styleElements: Array<Element>;

export const bootstrap = reactLifecycles.bootstrap;
export const mount = [
  // Add CSS styles to the page before React app is loaded
  async () => {
    if (styleElements) {
      document.head.append(...styleElements);
    }
  },
  reactLifecycles.mount,
  // Keep references to added style tags for the app so they can be remounted again
  async () => {
    if (!styleElements) {
      styleElements = Array.from(
        document.getElementsByClassName('amgen-rtsensing-about-page'),
      );
    }
  },
];
export const unmount = [
  reactLifecycles.unmount,
  // Remove styles once the app has unmounted
  async () => {
    styleElements.forEach(element => element.remove());
  },
];
